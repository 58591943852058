import React from 'react';
import {Link} from "react-router-dom";
import LogoImage from '../../images/header/logo_white.png';
import LogoImageBlack from '../../images/header/logo.png';
import '../../css/header.css'

function MenuList(props) {
  return (
    <li><Link to={props.link} onClick={props.onClick}>{props.enName}<span>{props.jpName}</span></Link></li>
  );
}

function MenuBtn(props) {
  return (
    <li className="gnbtn_wrap"><button className={props.menuActive? 'gn_btn active': 'gn_btn'} type="button" onClick={props.onClick}><span></span><span></span><span></span></button></li>
  );
}
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    }
  }

  clickMenu() {
    this.setState({
      menuActive: !this.state.menuActive,
    });
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  clickList() {
    this.clickMenu();
    this.scrollTop();
  }

 

  render() {
    let menuActive = this.state.menuActive;
    return (
      <header>
        <nav id="gnav">
          <div className="gn_header">
            <ul>
              <li><Link to="/" onClick={() => this.scrollTop()}><img src={LogoImage} alt="ロゴ" id="headerlogo" /></Link></li>
              {/* <MenuBtn 
                menuActive={menuActive}
                onClick={() => this.clickMenu()}
              /> */}
            </ul>
          </div>
          <div className={menuActive? 'gn_body active': 'gn_body'}>
            <div>
              <ul>
                <MenuList link='/' enName='Top' jpName='トップ' onClick={() => this.clickList()} />
                <MenuList link='/news' enName='News' jpName='お知らせ' onClick={() => this.clickList()} />
                <MenuList link='/about' enName='About' jpName='アルティパス武について' onClick={() => this.clickList()} />
                <MenuList link='/service' enName='Service' jpName='サービス' onClick={() => this.clickList()} />
                <MenuList link='/facility' enName='Facility' jpName='施設概要' onClick={() => this.clickList()} />
                <MenuList link='/recruit' enName='Recruit' jpName='採用情報' onClick={() => this.clickList()} />
                <MenuList link='/contact' enName='Contact' jpName='お問い合わせ' onClick={() => this.clickList()} />
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}