import React from 'react';
import src from '../images/about/main.jpg';
import MainImg from './main-img/main-img';

function setImgProps() {
  const p = {
    src: src,
    icon: 'fas fa-building',
    jp: '会社概要',
    en: 'About Us',
  }
  return p;
}

export default function About() {
  const mainImgProps = setImgProps(); //メインイメージの引数セット

  return (
    <main>
      <MainImg
        src={mainImgProps.src}
        icon={mainImgProps.icon}
        jp={mainImgProps.jp}
        en={mainImgProps.en}
      />
      <div className='content_wrap'>
        <h2></h2>
      </div>
    </main>
  );

}
