import React from 'react';
import TabMenu from './tabmenu/tab-menu';


export default function PageTabMenu() {
  return (
    <main className='top'>
      <TabMenu />
    </main>
  );

}
