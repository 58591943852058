import React from 'react';
import {Link, useLocation} from "react-router-dom";
import '../../css/home-menu.css'

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default function HomeMenu(){

    return (
      <div className='homemenu'>
        <ul>
          <li><Link onClick={() => scrollTop()}to="/tabmenu"><div><i className="fas fa-layer-group"></i><span>TabMenu</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/news"><div><i className="fas fa-book-open"></i><span>News</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/about"><div><i className="fas fa-building"></i><span>About</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/service"><div><i className="fas fas fa-seedling"></i><span>Service</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/contact"><div><i className="fas fa-info-circle"></i><span>Contact</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/recruit"><div><i className="fas fa-user"></i><span>Recruit</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/recruit"><div><i className="fas fa-user"></i><span>Recruit</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/service"><div><i className="fas fas fa-seedling"></i><span>Service</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/contact"><div><i className="fas fa-info-circle"></i><span>Contact</span></div></Link></li>
        </ul>
      </div>
    );
  }