import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './css/style.css'
import Header from './component/header/header.js'
import Top from './component/top.js'
import About from './component/about.js'
import News from './component/news.js'
import Service from './component/service'
import Contact from './component/contact'
import Info from './component/info/info'
import PageHomeMenu from './component/pageHomeMenu';
import PageTabMenu from './component/pageTabMenu';
import AppMenu from './component/appmenu/app-menu'
import {
  BrowserRouter,
  Routes,
  Route,
  Location,
  useLocation
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/tabmenu" element={<PageTabMenu />} />
        <Route path="/news" element={<News />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Info />
      <AppMenu />
    </BrowserRouter>
  );
}


function NotFound() {
  return (
    <main>
      <div className='content_wrap'>
        <h2>Not Found Page　まだつくってねえ</h2>
      </div>
    </main>
  );
}
// ========================================

ReactDOM.render(
  <App />,
  document.getElementById('root')
);