import React from 'react';
import {Link, useLocation} from "react-router-dom";
import '../../css/app-menu.css';

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default function AppMenu(){
  const location = useLocation();
    return (
      <div className='appmenu'>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}><Link to='/' onClick={() => scrollTop()}><i className="fas fa-home"></i><span>Home</span></Link></li>
          <li className={location.pathname === '/about' ? 'active' : ''}><Link to='/about' onClick={() => scrollTop()}><i className="fas fas fa-building"></i><span>About</span></Link></li>
          <li className={location.pathname === '/news' ? 'active' : ''}><Link to='/news' onClick={() => scrollTop()}><i className="fas fa-book-open"></i><span>News</span></Link></li>
          <li className={location.pathname === '/tabmenu' ? 'active' : ''}><Link to='/tabmenu' onClick={() => scrollTop()}><i className="fas fa-layer-group"></i><span>Tab Menu</span></Link></li>
        </ul>
      </div>
    );
  }
