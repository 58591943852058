import React from 'react';
import { Link } from "react-router-dom";
import '../../css/info.css'

export default class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoActive: false,
    }
  }

  clickInfo() {
    this.setState({
      infoActive: !this.state.infoActive,
    });
  }

  render() {
    let infoActive = this.state.infoActive;
    return (
      <div>
        <div className={infoActive ? 'infobg active' : 'infobg'} onClick={() => this.clickInfo()}></div>
        <div className={infoActive ? 'info show' : 'info'}>
          <button type="button" onClick={() => this.clickInfo()}>
          <p><i className="fas fa-info-circle"></i>各種ご案内</p>
          <div className='infotext-wrap'><span>電話をかけたい/一般的なお問い合わせをしたい/サービスに関するお問い合わせをしたい/採用について知りたい</span></div>
          </button>
          <ul>
            <li><Link to='/about' onClick={() => this.clickInfo()}><i className="fas fa-phone-volume"></i>電話をかけたい</Link></li>
            <li><Link to='/service' onClick={() => this.clickInfo()}><i className="fas fa-info-circle"></i>一般的なお問い合わせをしたい</Link></li>
            <li><Link to='/' onClick={() => this.clickInfo()}><i className="fas fa-info-circle"></i>サービスに関するお問い合わせをしたい</Link></li>
            <li><Link to='/recruit' onClick={() => this.clickInfo()}><i className="fas fa-male"></i>採用について知りたい</Link></li>
          </ul>
        </div>
      </div>

    );
  }
}