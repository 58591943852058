import React from 'react';
import src from '../images/contact/main.jpg';
import MainImg from './main-img/main-img';

export default class Contact extends React.Component {
  setImgProps(){
    const p = {
      src: src,
      icon : 'fas fa-info-circle',
      jp : 'お問い合わせ',
      en : 'Contact',
    }
    return p;
  }

  render() {
    const mainImgProps = this.setImgProps(); //メインイメージの引数セット

    return (
      <main>
        <MainImg 
          src={mainImgProps.src}
          icon={mainImgProps.icon}
          jp={mainImgProps.jp}
          en={mainImgProps.en}
        />
        <div className='content_wrap'>
          <h2></h2>
        </div>
      </main>
    );

  }
}