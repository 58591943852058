import React from 'react';
import MainImage from '../images/top/main.jpg';
import HomeMenu from '../component/homemenu/home-menu'
import TabMenu from '../component/tabmenu/tab-menu'

export default class Top extends React.Component {
  render() {
    const bkImg = {
      backgroundImage: `url(${MainImage})`,
    };
    return (
      <main className='top'>
        {/* <img src={MainImage} className="topimg"></img> */}
        <HomeMenu />
      </main>
    );
  }
}