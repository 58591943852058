import React from 'react';
import {Link, useLocation} from "react-router-dom";
import '../../css/tab-menu.css';
import bksrc from '../../images/tabmenu/okura.png';

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default function TabMenu(){
  const bkImg ={
    backgroundImage: `url('${bksrc}')`
  };

    return (
      <div className='tabmenu'>
        <ul>
          <li><Link onClick={() => scrollTop()}to="/"><p>株式会社オークラ ニッコー ホテルマネジメント</p><div style={bkImg} className='tabimg'></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/news"><div><i className="fas fa-book-open"></i><span>News</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/about"><div><i className="fas fa-building"></i><span>About</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/service"><div><i className="fas fas fa-seedling"></i><span>Service</span></div></Link></li>
          <li><Link onClick={() => scrollTop()}to="/contact"><div><i className="fas fa-info-circle"></i><span>Contact</span></div></Link></li>
        </ul>
      </div>
    );
  }