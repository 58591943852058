import React from 'react';
import '../../css/main-img.css'

export default class MainImg extends React.Component {
  render() {
    
    const bkImg = {
      backgroundImage: `url(${this.props.src})`,
    };
    window.addEventListener('scroll', function(){
      let y = window.scrollY /3;
      if(document.getElementById('mainimg')){
        document.getElementById('mainimg').style.backgroundPositionY = `-${y}px`;
      }
    });
    return (
      <div style={bkImg} className="mainimg" id="mainimg" onScroll={() => this.scrollBkImg()}>
        <h2 className='pageh2'>
          <i className={this.props.icon}></i>
          <span className='page_jp'>{this.props.jp}</span>
          <span className='page_en'>{this.props.en}</span>
        </h2>
      </div>
    );
  }
}